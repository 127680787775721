import React from 'react'
import styles from './styles.module.scss'
import CommonIcon from '../../assets/images/rarity/rarity-common.svg'
import RareIcon from '../../assets/images/rarity/rarity-rare.svg'
import SuperareIcon from '../../assets/images/rarity/rarity-superare.svg'
import UniqueIcon from '../../assets/images/rarity/rarity-unique.svg'

const iconList: { [key: string]: string; common: string; rare: string; superrare: string; unique: string } = {
    common: CommonIcon,
    rare: RareIcon,
    superrare: SuperareIcon,
    unique: UniqueIcon,
}

export const RarityIcon = ({ name }: { name: string }) => {
    return (
        <span className={styles.rarityIcon}>
            <img className={styles.rarityIcon__img} src={iconList[name.toLowerCase()]} alt={name + ' icon'} />
        </span>
    )
}
