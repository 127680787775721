import Web3 from 'web3'
import { web3ContextTypes } from '../store/web3Context/reducer'
import { nftAuctionPools, nftPools, nftStakes } from './web3.config'

const UNIVERSAL_GAS_PRICE = '70'

export const createWeb3Socket = () => {
    const web3 = new Web3(new Web3.providers.WebsocketProvider(process.env.NEXT_PUBLIC_INFURA_WSS ?? ''))
    const nftAuctionPool = nftAuctionPools[0]
    const instance = new web3.eth.Contract(nftAuctionPool.abi, nftAuctionPool.address)

    return { instance, web3 }
}

export const getWeb3Provider = (ctx: web3ContextTypes) => {
    if (!ctx.active) {
        return null
    }
    const provider = ctx.library.provider
    if (!provider) {
        return null
    }

    const web3 = new Web3(provider)

    return web3
}

const getNftAuctionContract = (ctx: web3ContextTypes) => {
    const web3 = getWeb3Provider(ctx)
    if (!web3) return null
    const nftAuctionPool = nftAuctionPools[0]
    return new web3.eth.Contract(nftAuctionPool.abi, nftAuctionPool.address)
}

const getNftAuctionTokenContract = (ctx: web3ContextTypes) => {
    const web3 = getWeb3Provider(ctx)
    if (!web3) return null
    const nftAuctionPool = nftAuctionPools[0]
    return new web3.eth.Contract(nftAuctionPool.nftAbi, nftAuctionPool.nftAddress)
}

export const getNftAuctionById = async (auctionId: number, ctx: web3ContextTypes) => {
    const nftAuctionContract = getNftAuctionContract(ctx)
    if (!nftAuctionContract) return false

    try {
        const auction = await nftAuctionContract.methods.auctionsById(auctionId).call()
        return auction
    } catch (ex) {
        return false
    }
}

export const nftGetAuctionBalance = async (auctionId: number, account: string, ctx: web3ContextTypes) => {
    const web3 = getWeb3Provider(ctx)
    if (!web3 || !account) return null
    const nftAuctionContract = getNftAuctionContract(ctx)
    if (!nftAuctionContract) return null

    try {
        var balance = await nftAuctionContract.methods.balanceOf(account, auctionId).call()
        balance = parseFloat(web3.utils.fromWei(`${balance ? balance : 0}`, `ether`))
        return balance
    } catch (ex) {
        return 0
    }
}

export const getBalanceOfAuction = async (account: string, tokenId: number, context: web3ContextTypes) => {
    try {
        const nftTokenContract = getNftAuctionTokenContract(context)
        if (!nftTokenContract) return null
        const balance = await nftTokenContract.methods.balanceOf(account, tokenId).call()
        return { balance }
    } catch (ex) {
        return ex
    }
}

export const nftAuctionBID = async (
    payload: { auctionId: number; amount: number; account: string },
    ctx: web3ContextTypes
) => {
    const { auctionId, amount, account } = payload

    const web3 = getWeb3Provider(ctx)
    if (!web3) return null

    const nftAuctionContract = getNftAuctionContract(ctx)
    if (!nftAuctionContract) return null

    try {
        const result = await nftAuctionContract.methods
            .bid(auctionId)
            .send({ from: account, value: web3.utils.toWei(amount.toFixed(4), 'ether') })

        return result
    } catch (ex) {
        return ex
    }
}

export const nftGetAuctionHighestBid = async (auctionId: number, ctx: web3ContextTypes) => {
    try {
        const web3 = getWeb3Provider(ctx)
        if (!web3) {
            return null
        }
        const nftAuctionContract = getNftAuctionContract(ctx)
        if (!nftAuctionContract) return 0
        let highestBid = await nftAuctionContract.methods.highestBid(auctionId).call()
        highestBid = parseFloat(web3.utils.fromWei(highestBid, `ether`))
        return highestBid
    } catch (ex) {
        return ex
    }
}

export const nftAuctionWithdraw = async (payload: { auctionId: number; account: string }, ctx: web3ContextTypes) => {
    const { auctionId, account } = payload

    const web3 = getWeb3Provider(ctx)
    if (!web3) return null

    const nftAuctionContract = getNftAuctionContract(ctx)
    if (!nftAuctionContract) return null

    try {
        const tx = await nftAuctionContract.methods.withdraw(auctionId).send({ from: account })
        return tx
    } catch (ex) {
        return ex
    }
}

export const getNftTransactionHash = async (highestBid: string, highestBidder: string, context: web3ContextTypes) => {
    try {
        const nftAuctionContract = getNftAuctionContract(context)
        if (!nftAuctionContract) return null
        const events = await nftAuctionContract.getPastEvents('Ended', { fromBlock: 0, toBlock: 'latest' })
        let hash = null
        if (events.length === 0) return null
        for (let i = 0; i < events.length; i++) {
            const values = events[i].returnValues
            if (values.user === highestBidder && values.amount === highestBid) {
                hash = events[i].transactionHash
            }
        }
        return hash
    } catch (error) {
        return null
    }
}

export const nftGetCardFee = async (cardId: string, poolId: number, ctx: web3ContextTypes) => {
    const nftPool = nftPools.find((pool) => {
        return pool.id === poolId
    })

    if (!nftPool) return null

    const web3 = getWeb3Provider(ctx)
    if (!web3) {
        return null
    }

    const asset = nftStakes.find((asset) => {
        return asset.id === nftPool.nftStakeId
    })

    if (!asset) return null

    const stakeContract = new web3.eth.Contract(asset.stakeAbi, asset.stakeAddress)
    const nftContract = new web3.eth.Contract(asset.nftAbi, asset.nftAddress)
    try {
        const fee = await stakeContract.methods.cardMintFee(poolId, +cardId).call()
        const maxSupply = await nftContract.methods.maxSupply(+cardId).call()
        const totalSupply = await nftContract.methods.totalSupply(+cardId).call()
        return { fee: web3.utils.fromWei(fee, 'ether'), maxSupply, totalSupply }
    } catch (ex) {
        return null
    }
}

export const getGasPrice = async () => {
    //HOT FIX
    try {
        const url = 'https://gasprice.poa.network/'
        const resp = await fetch(url)
        let priceJSON

        if (resp.ok) {
            const priceString = await resp.json()
            priceJSON = priceString
        }

        if (priceJSON) {
            return priceJSON.fast.toFixed(0)
        }
        return UNIVERSAL_GAS_PRICE
    } catch (e) {
        return UNIVERSAL_GAS_PRICE
    }
}

export const nftRedeem = async (
    payload: { pool: number; token: number; amount: number; account: string },
    context: web3ContextTypes
) => {
    try {
        const { pool: nftPoolId, token: tokenId, amount, account } = payload
        const pool = nftPools.find((pool) => pool.id === nftPoolId)
        if (!pool) return null

        const asset = nftStakes.find((asset) => asset.id === pool.nftStakeId)
        if (!asset) return null

        const web3 = getWeb3Provider(context)

        if (!web3) {
            return { isError: true, message: 'Web3 is not provided' }
        }
        const stakeContract = new web3.eth.Contract(asset.stakeAbi, asset.stakeAddress)
        const amountToSend = web3.utils.toWei(`${amount}`, 'ether')

        const resp = await stakeContract.methods.redeem(nftPoolId, tokenId).send({
            from: account,
            gasPrice: web3.utils.toWei(await getGasPrice(), 'gwei'),
            value: amountToSend,
        })

        return { isError: false, data: resp }
    } catch (e) {
        return {
            isError: true,
            message: e.message,
        }
    }
}

export const signUserForClaim = async (message: string, context: web3ContextTypes) => {
    try {
        const web3 = getWeb3Provider(context)
        if (!web3) {
            return null
        }

        const hash = context.account && (await web3.eth.personal.sign(message, context.account, ''))
        return {
            error: false,
            hash,
        }
    } catch (e) {
        return {
            error: true,
            message: e.message,
        }
    }
}
